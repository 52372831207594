import { LogsEvent } from '@datadog/browser-logs';
import { DefaultPrivacyLevel } from '@datadog/browser-rum';

const datadogClientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '';
const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME || 'local';
const isLocal = environmentName === 'local';

const KNOWN_ERRORS = {
  FLASH: ['XHR error GET https://api.maptiler.com/', 'XHR error GET https://basemaps.arcgis.com/'],
};

const isFlashError = (event: LogsEvent) =>
  new URL(event.view.url).pathname.startsWith('/flash/') &&
  KNOWN_ERRORS.FLASH.some(err => event.message.startsWith(err));

const getProperServiceName = (servicePath: string) => {
  switch (servicePath) {
    case 'sales':
    case 'admin-settings':
    case 'user-settings':
    case 'external-links':
      return 'tc3-sales';
    case 'truck-appointments':
      return 'tc3-appointment';
    case 'workflow':
      return 'tc3-workflow';
    case 'client-inventories':
      return 'tc3-inventory';
    case 'invoices':
      return 'tc3-invoicing';
    case 'projects':
    case 'claims':
    case 'purchase-requests':
    case 'purchase-orders':
    case 'purchase-invoices':
      return 'tc3-projects';
    case 'flash':
      return 'tc3-flash';
    case 'operational-sites':
      return 'tc3-operational-sites';
    case 'telemetry':
      return 'tc3-telemetry';
    case 'vessels':
      return 'tc3-vessel';
    default:
      return servicePath;
  }
};

export const datadogLogsConfiguration = {
  clientToken: datadogClientToken,
  env: environmentName,
  service: 'tc3-menu',
  beforeSend: (event: LogsEvent) => {
    if (event.status !== 'error') return true;

    // Don't send Flash errors
    if ([isFlashError(event)].some(Boolean)) return false;

    // Don't send errors when they happen in a background or inactive tab.
    if (document.hidden) return false;

    if (!navigator.onLine || document.wasDiscarded) {
      event.additionalBrowserInfo = {
        navigatorOnLine: navigator.onLine,
        documentWasDiscarded: document.wasDiscarded,
      };
    }

    const { pathname } = new URL(window.location.href);
    const servicePath = pathname.split('/')[1];

    if (servicePath) event.service = getProperServiceName(servicePath);

    return true;
  },
};

export const getDatadogLogsConfiguration = () => {
  return datadogClientToken && !isLocal ? datadogLogsConfiguration : null;
};

export const getDatadogRumConfiguration = () => {
  const allowedTracingUrls = isLocal
    ? []
    : [new RegExp(`^${window.location.origin.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}/[\\w-]+/api/.*$`)];

  return datadogClientToken && !isLocal
    ? {
        applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID || '',
        clientToken: datadogClientToken,
        env: environmentName,
        service: 'tc3-menu',
        version: require('../package.json').version, // eslint-disable-line @typescript-eslint/no-var-requires
        defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        startSessionReplayRecordingManually: true,
        allowedTracingUrls,
      }
    : null;
};
